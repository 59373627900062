import React, { useEffect, useState } from "react";
import { API } from "../../../api";
import { CircularProgress, Tooltip } from "@mui/material";
import { Snackbar } from "@material-ui/core";
import Alert from "@mui/material/Alert";
import ChallengeDownloadIcon from "@mui/icons-material/Download";
import ChallengeReviewSendIcon from "@mui/icons-material/Send";
import NewModal from "../../../components/NewModal";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";

import { Document, Packer, Paragraph, TextRun, ImageRun } from "docx";
import { saveAs } from "file-saver";
import axios from "axios";
import LinearButton from "../../../components/Button";

const SendChallenge = ({ challenge_id, goal_id, challenge_name }) => {
  const [groupAdminData, setGroupAdminData] = useState([]);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [showSendDocumentModal, setShowSendDocumentModal] = useState(false);

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isButtonProgress, setIsButtonProgress] = useState(false);

  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const [challengeId, setChallengeId] = useState(null);
  const [goalId, setGoalId] = useState(null);
  const [challengeName, setChallengeName] = useState("");

  const generateReviewDocument = (data) => {
    const docContent = [];

    // Iterate through the data to generate the document content
    for (const item of data.review) {
      if (item?.text) {
        docContent.push(
          new Paragraph({
            children: [new TextRun({ text: item.text, size: 24, break: 1 })],
            spacing: { after: 300 },
          })
        );
      }

      if (item?.question) {
        docContent.push(
          new Paragraph({
            children: [
              new TextRun({
                text: `Question: ${item.question}`,
                bold: true,
                size: 24,
              }),
            ],
          })
        );

        docContent.push(
          new Paragraph({
            children: [
              new TextRun({
                text: `User Response: ${item.user_response}`,
                size: 24,
              }),
            ],
            spacing: { after: 300 },
          })
        );
      }
    }

    // Create the Document
    const doc = new Document({
      sections: [
        {
          children: docContent,
        },
      ],
    });

    return doc;
  };
  const getChallengeData = async (challenge_id, goal_id) => {
    const { data, status } = await API.get(
      `challenge/review/${goal_id}/${challenge_id}`
    );
    return data;
  };
  const downloadChallengeDocument = async (
    challenge_id,
    goal_id,
    challenge_name
  ) => {
    if (!goal_id || !challenge_id) {
      setOpenSnackBar(true);
      setMessage("Goal Id or Challenge Id missing");
      return;
    }

    const challengeData = await getChallengeData(challenge_id, goal_id);
    const doc = generateReviewDocument(challengeData);

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `${challenge_name}.docx`);
    });
  };

  const handleSendChallengeClick = async (
    challenge_id,
    goal_id,
    challenge_name
  ) => {
    const activateTimer = () => {
      setTimeout(() => {
        setMessage("");
      }, 4000);
    };

    if (!goal_id || !challenge_id) {
      setOpenSnackBar(true);
      setMessage("Goal Id or Challenge Id missing");
      activateTimer();
      return;
    }

    setChallengeId(challenge_id);
    setGoalId(goal_id);
    setChallengeName(challenge_name);

    setShowSendDocumentModal(true);
    setIsLoading(true);
    try {
      const { data, status } = await API.get(
        "group/get-group-admin-emails-by-user"
      );

      if (data && status === 200) {
        const userEmail = localStorage.getItem("email");
        const filteredGroupData = [];

        data.forEach((item) => {
          item.groups.forEach((group) => {
            if (group.group_admin.email !== userEmail) {
              filteredGroupData.push({
                groupName: group.name,
                adminEmail: group.group_admin.email,
              });
            }
          });
        });
        setGroupAdminData(filteredGroupData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const getDownloadChallengeIcon = (challenge_id, goal_id, challenge_name) => {
    return (
      <div>
        <button
          onClick={() =>
            downloadChallengeDocument(challenge_id, goal_id, challenge_name)
          }
          className="flex space-x-1 bg-transparent items-center p-2 cursor-pointer"
        >
          <Tooltip title="Download Review Document" arrow placement="top">
            <svg
              className="w-6 h-5 -mt-2"
              style={{ color: "#2288EE", fill: "#2288EE" }}
              viewBox="0 0 20 20"
            >
              <ChallengeDownloadIcon />
            </svg>
          </Tooltip>
        </button>
      </div>
    );
  };

  const handleSendDocument = async () => {
    setIsButtonProgress(true);
    setMessage("");

    if (selectedRows.length < 1) {
      setSeverity("error");
      setMessage("Select at least one row.");
      setIsButtonProgress(false);
      return;
    }

    const challengeData = await getChallengeData(challengeId, goalId);
    const doc = generateReviewDocument(challengeData);

    const wordDoc = await Packer.toBlob(doc);

    try {
      const fd = new FormData();
      fd.append("document", wordDoc, "Challenge-Review.docx");
      fd.append("user_email", JSON.stringify(selectedRows));
      fd.append("challenge_name", challengeName);
      fd.append("goal_id", goalId);
      const { data, status } = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/group/challenge-doc`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (status === 200) {
        setSeverity("success");
        setMessage("Document successfully sent.");
      } else {
        setSeverity("error");
        setMessage("Failed to send the document.");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsButtonProgress(false);
    }
  };

  const getSendChallengeReviewIcon = (
    challenge_id,
    goal_id,
    challenge_name
  ) => {
    return (
      <div>
        <button
          onClick={() =>
            handleSendChallengeClick(challenge_id, goal_id, challenge_name)
          }
          className="flex space-x-1 bg-transparent items-center p-2 cursor-pointer"
        >
          <Tooltip
            title="Send Challenge Document For Review"
            arrow
            placement="top"
          >
            <svg
              className="w-6 h-5 -mt-2"
              style={{ color: "#2288EE", fill: "#2288EE" }}
              viewBox="0 0 20 20"
            >
              <ChallengeReviewSendIcon />
            </svg>
          </Tooltip>
        </button>
      </div>
    );
  };

  useEffect(() => {
    const headerData = ["Email", "Group"];
    const updatedColumns = headerData.map((headerText, index) => ({
      field: headerText.toLowerCase(),
      headerName: headerText,
      flex: 1,
      headerClassName: () => "bg-gray-100 border-b-2 border-gray-200",
    }));

    const updatedRows = groupAdminData.map((member, index) => ({
      id: index,
      email: member?.adminEmail,
      group: member?.groupName,
    }));

    setRows(updatedRows);
    setColumns(updatedColumns);
  }, [groupAdminData]);

  useEffect(() => {
    const selectedEmails = rowSelectionModel
      .map((selectedId) => rows.find((item) => item.id === selectedId))
      .map(({ email }) => email);

    setSelectedRows(selectedEmails);
  }, [rowSelectionModel]);

  function CustomToolbar() {
    return <GridToolbarContainer></GridToolbarContainer>;
  }

  return (
    <div>
      <div className="flex flex-row gap-2 flex-wrap ">
        <div>
          {getDownloadChallengeIcon(challenge_id, goal_id, challenge_name)}
        </div>
        <div>
          {getSendChallengeReviewIcon(challenge_id, goal_id, challenge_name)}
        </div>
      </div>

      {showSendDocumentModal && (
        <NewModal
          ModalStyle={{
            width: "100vh",
          }}
          heading={
            <div className="block text-center">
              Send Challenge Review Document
            </div>
          }
          body={
            <div className="mx-16">
              <div className="w-full">
                <div>
                  {isLoading ? (
                    <div className="flex justify-center">
                      <CircularProgress size={50} />
                    </div>
                  ) : groupAdminData.length ? (
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      keyField="id"
                      checkboxSelection
                      onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowSelectionModel(newRowSelectionModel);
                      }}
                      rowSelectionModel={rowSelectionModel}
                      slots={{
                        toolbar: () => <CustomToolbar />,
                      }}
                      sx={{
                        "& .MuiDataGrid-columnHeaderCheckbox": {
                          backgroundColor: "#F3F4F6",
                          borderBottom: "2px solid #e5e7eb",
                        },
                      }}
                    />
                  ) : (
                    <div className="flex justify-center items-start bg-white ">
                      <div className="text-center p-8 bg-white-200 rounded-t-lg shadow-md w-full">
                        <p className="text-3xl font-bold text-gray-500 mb-4">
                          No Admins Found
                        </p>
                        <p className="text-gray-700">
                          It seems that you're not currently part of any group.
                          Please reach out to your organization owner to be
                          added.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="flex justify-center w-full mt-14">
                <LinearButton
                  onClick={handleSendDocument}
                  text={
                    isButtonProgress ? <CircularProgress size={30} /> : "Send"
                  }
                />
              </div>

              <div className="mt-6 flex justify-center inline-block ">
                {message && message !== "" && (
                  <Alert severity={severity}>{message}</Alert>
                )}
              </div>
            </div>
          }
          onClose={() => {
            setShowSendDocumentModal(false);
            setChallengeId(null);
            setGoalId(null);
            setMessage("");
            setChallengeName("");
          }}
        />
      )}

      <Snackbar
        open={openSnackBar}
        autoHideDuration={4000}
        onClose={() => setOpenSnackBar(false)}
        TransitionComponent={`SlideTransition`}
      >
        <Alert
          severity={"error"}
          sx={{
            width: "100%",
            // color: "white",
            // "& .MuiAlert-icon": {
            //   color: "white",
            // },
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SendChallenge;
