import { TextField } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import { useStoreState, useStoreActions } from "easy-peasy";
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { API } from "../../../api";
import NewModal from "../../../components/NewModal";
import "./index.css";
import EditIcon from "@material-ui/icons/Edit";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const COUNTRY = [
  { value: "", text: "" },
  { value: "india", text: "India" },
  { value: "usa", text: "USA" },
  { value: "uk", text: "UK" },
  { value: "canada", text: "Canada" },
  { value: "australia", text: "Australia" },
  { value: "singapore", text: "Singapore" },
  { value: "germany", text: "Germany" },
  { value: "hong_kong", text: "Hong Kong" },
];

const GENDER = [
  { value: "", text: "" },
  { value: "male", text: "Male" },
  { value: "female", text: "Female" },
  { value: "other", text: "Other" },
];

const EDUCATION_LEVEL = [
  { value: "", text: "" },
  { value: "high_school", text: "High School" },
  { value: "bachelor", text: "Bachelor's" },
  { value: "master", text: "Master's" },
  { value: "professional", text: "Professional" },
  { value: "doctorate", text: "Doctorate" },
  { value: "post-doctorate", text: "Post Doctorate" },
  { value: "other", text: "Others" },
];

const EDUCATION_MAJOR = [
  { value: "", text: "" },
  { value: "none", text: "None" },
  { value: "arts", text: "Arts" },
  { value: "finance", text: "Finance" },
  { value: "engineering", text: "Engineering" },
  { value: "medicine", text: "Medicine" },
  { value: "law", text: "Law" },
  { value: "management", text: "Management" },
  { value: "other", text: "Others" },
];

const YEARS_OF_EXPERIENCE = [
  { value: "", text: "" },
  { value: "less_than_one_year", text: "< 1 year" },
  { value: "one_to_three_years", text: "1 - 3 years" },
  { value: "three_to_five_years", text: "3 - 5 years" },
  { value: "five_to_eight_years", text: "5 - 8 years" },
  { value: "more_than_eight_years", text: "> 8 years" },
];

const ROLE_TYPE = [
  { value: "", text: "" },
  { value: "individual_contributor", text: "Individual Contributor" },
  { value: "people_manager", text: "People Manager" },
];

const WORK_TYPE = [
  { value: "", text: "" },
  { value: "office", text: "Office" },
  { value: "remote", text: "Remote" },
  { value: "hybrid", text: "Hybrid" },
];

const TYPE_OF_COMPANY = [
  { value: "", text: "" },
  { value: "industrial_and_materials", text: "Industrial & Materials" },
  { value: "consumer_goods", text: "Consumer Goods" },
  { value: "healthcare", text: "Healthcare" },
  { value: "education", text: "Education" },
  { value: "information_technology_and_services", text: "IT and services" },
  { value: "internet", text: "Internet" },
  { value: "financial", text: "Financial" },
  { value: "other", text: "Others" },
];

const NO_OF_DIRECT_REPORTEES = [
  { value: "", text: "" },
  { value: "zero", text: "0" },
  { value: "one", text: "1" },
  { value: "two_to_four", text: "2 to 4" },
  { value: "four_to_seven", text: "4 to 7" },
  { value: "more_than_seven", text: "> 7" },
];

const countries = ["in", "us", "gb", "ca", "au", "sg", "de", "hk"];

export default function UserProfile() {
  const [basicData, setBasicData] = useState({
    date_of_birth: null,
    phone: "",
    country: "",
    city: "",
    gender: "",
  });
  const [educationData, setEducationData] = useState({
    education_level: "",
    education_major: "",
  });
  const [professionalData, setProfessionalData] = useState({
    years_of_experience: "",
    role_type: "",
    role_level: "",
    role_area: "",
    type_of_company: "",
    salary_level: "",
    no_of_direct_reportees: "",
  });

  const [heading, setHeading] = useState("");
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [image, setImage] = useState();
  const [imagePreview, setImagePreview] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const [buttonLoading, setButtonLoading] = useState(false);

  const inputFileRef = useRef();

  const user = useStoreState((s) => s.user.data);
  const history = useHistory();
  const saveUser = useStoreActions((a) => a.user.update);

  const _handleEducationChange = (e) => {
    setEducationData({ ...educationData, [e.target.name]: e.target.value });
  };

  const _handleProfessionalChange = (e) => {
    setProfessionalData({
      ...professionalData,
      [e.target.name]: e.target.value,
    });
  };

  const _handleBasicChange = (e, value) => {
    if (value) {
      console.log(value);
      setBasicData({ ...basicData, phone: value });
    } else {
      setBasicData({ ...basicData, [e.target.name]: e.target.value });
    }
  };

  const _updateEducation = async () => {
    const { status } = await API.post(
      "user-persona/update-education",
      educationData
    );
    return status;
  };

  const _updateProfession = async () => {
    const { status } = await API.post(
      "user-persona/update-profession",
      professionalData
    );
    return status;
  };

  const _updateBasic = async () => {
    const { status } = await API.post("user/update", basicData);
    return status;
  };

  useEffect(() => {
    async function getProfileData() {
      const basic = await API.get("user", "details");
      setImageUrl(basic.data.newPhotoURL);
      const education = await API.get("user-persona", "education");
      const profession = await API.get("user-persona", "profession");
      if (basic.status !== 200) history.push("/user/login");
      setEducationData(education.data);
      setProfessionalData(profession.data);
      setBasicData(basic.data);
    }
    getProfileData();
  }, []);

  const _handleSubmit = async () => {
    setButtonLoading(true);
    try {
      let newPhotoURL, uploadPhotoStatus;
      if (isFileUploaded) {
        const result = await _handleImageSubmit();
        newPhotoURL = result.newPhotoURL;
        uploadPhotoStatus = result.status;
        if (uploadPhotoStatus !== 200)
          throw new Error(result.error || "Failed to upload photo.");
      }

      const basicStatus = await _updateBasic();
      if (basicStatus !== 200)
        throw new Error(
          basicStatus.message || "Failed to update basic information."
        );

      const professionStatus = await _updateProfession();
      if (professionStatus !== 200)
        throw new Error(
          professionStatus.message || "Failed to update profession information."
        );

      const educationStatus = await _updateEducation();
      if (educationStatus !== 200)
        throw new Error(
          educationStatus.message || "Failed to update education information."
        );

      setShowModal(true);
      setHeading("Saved!");
      setMessage("Your data has been saved successfully");

      if (uploadPhotoStatus === 200) {
        saveUser({ ...user, newPhotoURL });
      }
    } catch (error) {
      console.error("Submission error:", error);
      setShowModal(true);
      setHeading("Error!");
      setMessage(error.message || "An error occurred, please try again.");
    } finally {
      setButtonLoading(false);
    }
  };

  const openFileUpload = () => {
    inputFileRef.current.click();
  };

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(image);
    } else {
      setImagePreview(null);
    }
  }, [image]);

  const _handleImageSubmit = async () => {
    const fd = new FormData();
    fd.append("image", image, image.name);

    try {
      const { data, status } = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/user/photo`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return { newPhotoURL: data.newPhotoURL, status };
    } catch (error) {
      const errorMessage = error?.response?.data?.error?.message;

      if (errorMessage) {
        const message =
          errorMessage === "File too large"
            ? `${errorMessage} max 1mb allowed.`
            : `${errorMessage}.`;

        return { error: message };
      }

      return { error: "An error occurred while uploading the image." };
    }
  };

  return (
    <div>
      <div>
        <div className="body-small-1 pb-4">
          <div className="flex flex-row gap-4 items-center">
            <div>
              <input
                type="file"
                ref={inputFileRef}
                accept="image/png, image/jpeg, image/webp"
                style={{ display: "none" }}
                onChange={(e) => {
                  setImage(e.target.files[0]);
                  setIsFileUploaded(true);
                }}
              />
            </div>
            <div className="relative w-16 h-16 min-w-16 min-h-16 rounded-full overflow-hidden">
              <img
                alt="profile_pic"
                className="w-full h-full object-cover"
                src={
                  imagePreview
                    ? imagePreview
                    : imageUrl
                    ? `${imageUrl}`
                    : `${process.env.REACT_APP_S3_URL}/default_photo.png`
                }
              />
              <div className="absolute bottom-0 right-0 m-1 z-10 cursor-pointer">
                <div className="flex items-center justify-center w-6 h-6 bg-white rounded-full">
                  <EditIcon
                    color="primary"
                    fontSize="small"
                    onClick={openFileUpload}
                  />
                </div>
              </div>
            </div>
            <div>
              {basicData?.first_name} {basicData?.last_name}
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex items-center px-4"
        style={{ backgroundColor: "#E9F3FD", height: "2.625rem" }}
      >
        <h6>Basic Information</h6>
      </div>
      <div>
        <div className="flex flex-col sm:flex-row p-4 gap-4">
          <div className="flex flex-col w-full sm:w-2/5">
            <label className="body-small-1">Email address</label>
            <input
              className="input-box body-small-1 "
              value={basicData?.email}
              disabled={true}
            />
          </div>
          <div className="flex flex-col w-full sm:w-2/5">
            <label className="body-small-1">Date Of Birth</label>
            <input
              className="input-box body-small-1"
              type="date"
              format="MM-DD-YYYY"
              name="date_of_birth"
              value={new Date(basicData.date_of_birth)
                .toISOString()
                .substr(0, 10)}
              onChange={_handleBasicChange}
            />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row p-4 gap-4">
          <div className="flex flex-col w-full sm:w-2/5">
            <label className="body-small-1">Phone Number</label>
            <div className="w-full">
              <ReactPhoneInput
                country={"in"}
                name="phone"
                value={basicData.phone}
                onChange={(value, country, e) => _handleBasicChange(e, value)}
                onlyCountries={countries}
                inputStyle={{ width: "100%", height: "38px" }}
              />
            </div>
          </div>
          <div className="flex flex-col w-full sm:w-2/5">
            <label className="body-small-1">Country</label>
            <select
              onChange={_handleBasicChange}
              name="country"
              className="input-box body-small-1"
            >
              {COUNTRY.map((option, index) => {
                return (
                  <option
                    value={option.value}
                    key={index}
                    selected={option.value === basicData.country ? true : false}
                  >
                    {option.text}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row p-4 gap-4">
          <div className="flex flex-col w-full sm:w-2/5">
            <label className="body-small-1">City</label>
            <input
              className="input-box body-small-1 "
              name="city"
              value={basicData.city}
              onChange={_handleBasicChange}
            />
          </div>
          <div className="flex flex-col w-full sm:w-2/5">
            <label className="body-small-1">Gender</label>
            <select
              onChange={_handleBasicChange}
              name="gender"
              className="input-box body-small-1"
            >
              {GENDER.map((option, index) => {
                return (
                  <option
                    value={option.value}
                    key={index}
                    selected={option.value === basicData.gender ? true : false}
                  >
                    {option.text}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div
          className="flex items-center px-4"
          style={{ backgroundColor: "#E9F3FD", height: "2.625rem" }}
        >
          <h6>Academic</h6>
        </div>
        <div>
          <div className="flex flex-col sm:flex-row p-4 gap-4">
            <div className="flex flex-col w-full sm:w-2/5">
              <label className="body-small-1">Education Level</label>
              <select
                onChange={_handleEducationChange}
                name="education_level"
                className="input-box body-small-1"
              >
                {EDUCATION_LEVEL.map((option, index) => {
                  return (
                    <option
                      value={option.value}
                      selected={
                        option.value === educationData.education_level
                          ? true
                          : false
                      }
                      key={index}
                    >
                      {option.text}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="flex flex-col w-full sm:w-2/5">
              <label className="body-small-1">Education Major</label>
              <select
                onChange={_handleEducationChange}
                name="education_major"
                className="input-box body-small-1"
              >
                {EDUCATION_MAJOR.map((option, index) => {
                  return (
                    <option
                      value={option.value}
                      selected={
                        option.value === educationData.education_major
                          ? true
                          : false
                      }
                      key={index}
                    >
                      {option.text}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div
          className="flex items-center px-4"
          style={{ backgroundColor: "#E9F3FD", height: "2.625rem" }}
        >
          <h6>Professional</h6>
        </div>
        <div>
          <div className="flex flex-col sm:flex-row p-4 gap-4">
            <div className="flex flex-col w-full sm:w-2/5">
              <label className="body-small-1">Years of Experience</label>
              <select
                onChange={_handleProfessionalChange}
                name="years_of_experience"
                className="input-box body-small-1"
              >
                {YEARS_OF_EXPERIENCE.map((option, index) => {
                  return (
                    <option
                      value={option.value}
                      selected={
                        option.value === professionalData.years_of_experience
                          ? true
                          : false
                      }
                      key={index}
                    >
                      {option.text}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="flex flex-col w-full sm:w-2/5">
              <label className="body-small-1">
                Office/Remote/Hybrid Worker
              </label>
              <select
                onChange={_handleProfessionalChange}
                name="work_type"
                className="input-box body-small-1"
              >
                {WORK_TYPE.map((option, index) => {
                  return (
                    <option
                      value={option.value}
                      selected={
                        option.value === professionalData.work_type
                          ? true
                          : false
                      }
                      key={index}
                    >
                      {option.text}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row p-4 gap-4">
            <div className="flex flex-col w-full sm:w-2/5">
              <label className="body-small-1">Role Type</label>
              <select
                onChange={_handleProfessionalChange}
                name="role_type"
                className="input-box body-small-1"
              >
                {ROLE_TYPE.map((option, index) => {
                  return (
                    <option
                      value={option.value}
                      selected={
                        option.value === professionalData.role_type
                          ? true
                          : false
                      }
                      key={index}
                    >
                      {option.text}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="flex flex-col w-full sm:w-2/5">
              <label className="body-small-1">Role Area</label>
              <input
                className="input-box body-small-1"
                name="role_area"
                value={professionalData.role_area}
                onChange={_handleProfessionalChange}
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row p-4 gap-4">
            <div className="flex flex-col w-full sm:w-2/5">
              <label className="body-small-1">Type of company</label>
              <select
                onChange={_handleProfessionalChange}
                name="type_of_company"
                className="input-box body-small-1"
              >
                {TYPE_OF_COMPANY.map((option, index) => {
                  return (
                    <option
                      value={option.value}
                      selected={
                        option.value === professionalData.type_of_company
                          ? true
                          : false
                      }
                      key={index}
                    >
                      {option.text}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="flex flex-col w-full sm:w-2/5">
              <label className="body-small-1">No of direct reportees</label>
              <select
                onChange={_handleProfessionalChange}
                name="no_of_direct_reportees"
                className="input-box body-small-1"
              >
                {NO_OF_DIRECT_REPORTEES.map((option, index) => {
                  return (
                    <option
                      value={option.value}
                      selected={
                        option.value === professionalData.no_of_direct_reportees
                          ? true
                          : false
                      }
                      key={index}
                    >
                      {option.text}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className=" flex flex-col gap-4 items-center md:items-start md:mx-4 mt-4">
        <button
          className="text-white w-32 py-4 px-8"
          style={{ background: "#003366", borderRadius: "10px" }}
          onClick={_handleSubmit}
        >
          {buttonLoading ? <CircularProgress size={20} /> : "Save"}
        </button>
      </div>
      {showModal && (
        <NewModal
          body={
            <div className="flex flex-col -mt-8 gap-4 justify-center items-center">
              <h5>{heading}</h5>
              <div>{message}</div>
            </div>
          }
          hasCloseButton={true}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
}
